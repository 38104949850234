import React, { ReactElement, Suspense, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";

import history from "../utils/history";

import {useTranslation } from "react-i18next";
import i18n from "../config/i18n";
import { I18nextProvider } from "react-i18next";

const Home = lazy(() => import("../views/home/App"));
const Error = lazy(() => import("../views/error/Error"));
const Access = lazy(() => import("../views/access/Access"));


const IndexRouter: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <Router history={history}>
      <Switch>
        <Suspense fallback={<p>Loading</p>}>
          <I18nextProvider i18n={i18n}>
            <div className="card">
              <h1>Videoatenció gencat</h1>
              <Route path="/:meetingId" component={Home} exact />
              <Route path="/:meetingId/access" component={Access} exact />
              <Route path="/earlyjoin" component={Error} exact />
              <Route path="/latejoin" component={Error} exact />
              <Route path="/finalized" component={Error} exact />
            </div>
          </I18nextProvider>
        </Suspense>
      </Switch>
    </Router>
  );
};

export default IndexRouter;
